import React, { useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import styles from './SearchField.module.scss'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'

/**
 * honestly, this is probably not a good idea, but we have
 * a very limited use case for now
 * @param {string} query
 */
const parseQueryString = query => {
  const [str] = query.split('?').reverse()

  return str
    .split('&')
    .map(pair => pair.split('='))
    .reduce(
      (acc, [key, val]) => ({
        ...acc,
        [decodeURIComponent(key)]: decodeURIComponent(val)
      }),
      {}
    )
}

export default () => {
  const { search: queryString } = useLocation()
  const { q: qraw = '' } = parseQueryString(queryString)
  const q = qraw.trim()
  let newPath = '/search'
  const { t } = useLanguage()
  const searchInput = useRef(null)

  useEffect(() => {
    searchInput.current.focus()
  })

  return (
    <div className={styles.input}>
      <input
        ref={searchInput}
        placeholder={t(`Search strings|search input placeholder`)}
        className='form-control'
        type='text'
        defaultValue={q}
        onKeyUp={e => {
          if (e.key !== 'Enter') {
            return
          }

          newPath = `/search?q=${encodeURIComponent(
            e.target.value
          )}`

          navigate(newPath, { replace: true })
        }}
      />
      <span
        className={styles.searchIcon}
      >
        <FontAwesomeIcon
          icon={faSearch}
        />
      </span>
    </div>
  )
}
